<template>
  <a-row :gutter="25" v-if="events.length">
    <a-col
      :xxl="7"
      :lg="8"
      :md="10"
      :sm="10"
      :xs="24"
      class="box-event"
      v-for="event in events" :key="event">
      <Suspense>
        <template #default>
          <RegisterStyleCard  style="height: 100%">
            <a-tooltip overlayClassName="event-card">
              <template #title v-if="(roles[0].nombre.toLowerCase() !== 'director de cct'
                                    && roles[0].nombre.toLowerCase() !== 'participante'
                                    && roles[0].nombre.toLowerCase() !== 'responsable de registro') && section === 'register' && event.creator">
                Tú has creado este evento</template>
              <div class="card-container"  style="height: 100%">
                <a-row :gutter="25"
                  class="card-container_header"
                  :class="event.status == 'Finalizado' || event.status == 'Cancelado' ? '' : 'hover'"
                  @click.stop="event.status == 'Finalizado' || event.status == 'Cancelado' ? '' : handlerSendEventDetail(event.id)"
                >
                  <img
                    style='object-fit: cover'
                    :src='event.front_page ? base_url+event.front_page : "https://via.placeholder.com/448x248"'
                    alt="cover_event" />
                </a-row>
                <a-row :gutter="25" class="card-container_body">
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                    <a-row class="list-data">
                      <a-col :xxl="24" :lg="24" :md="24" :xs="24" class="event-name-container"
                        :class="event.status == 'Finalizado' || event.status == 'Cancelado' ? '' : 'hover'"
                      >
                        <span class="event-name  break-text-two-lines title-bold-normal" @click.stop="event.status == 'Finalizado' || event.status == 'Cancelado' ? '' : handlerSendEventDetail(event.id)">
                          {{ event.title }}
                        </span>
                      </a-col>
                    </a-row>
                    <a-row class="list-data description-container">
                      <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                        <span class="event-description title-book">
                          {{ event.description }}
                        </span>
                      </a-col>
                    </a-row>
                    <a-row class="list-data">
                      <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                        <img class="logo" :src="require('@/static/img_events/SEJ enventos_mis eventos.svg')" alt="event date" />
                      </a-col>
                      <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                        <span class="title-bold-book event-date">
                          {{ formatDate(event.event_date) }}. {{removeSeconds(event.event_hour)}}.
                        </span>
                      </a-col>
                    </a-row>
                    <a-row class="list-data">
                      <a-col
                        :xxl="24" :lg="24" :md="24" :xs="24"
                        v-for="direction in event.headquarters" :key="direction" class="min-height">
                        <a-row v-if="direction.type === 'face-to-face'">
                          <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                            <img class="logo" :src="require('@/static/img_events/SEJ enventos_sede.svg')" alt="sede">
                          </a-col>
                          <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                            <div class="event-direction">
                              <div class="direction-name"> {{ direction.name }} </div>
                              <div style="height: 15px">
                                <span class="break-text-one-lines">
                                  {{ direction.street }}, {{ direction.references ? direction.references+',' : '' }}
                                  {{ direction.suburb ? direction.suburb+',' : '' }} {{ direction.postal_code+',' ? direction.postal_code : '' }} {{direction.municipality ? direction.municipality : '' }}
                                </span>
                              </div>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row v-if="direction.type === 'virtual' && event.link_showed" @click.stop>
                          <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                            <img class="logo" :src="require('@/static/img_events/SEJ enventos_sede.svg')" alt="sede">
                          </a-col>
                          <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                            <div class="event-direction">
                              <span class="event-link">{{direction.link}}</span>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row v-else-if="direction.type === 'both' && event.link_showed">
                          <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                            <img class="logo" :src="require('@/static/img_events/SEJ enventos_sede.svg')" alt="sede">
                          </a-col>
                          <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                            <div class="event-direction">
                              <div class="direction-name"> {{ direction.name }} </div>
                              <div style="height: 15px">
                                <span class="break-text-one-lines">
                                  {{ direction.street }}, {{ direction.references ? direction.references+',' : '' }}
                                  {{ direction.suburb ? direction.suburb+',' : '' }} {{ direction.postal_code+',' ? direction.postal_code : '' }} {{direction.municipality ? direction.municipality : '' }}
                                </span>
                              </div>
                            </div>
                          </a-col>
                        </a-row>
                      </a-col>
                    </a-row>
                    <a-row class="list-data">
                      <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                        <img class="logo" :src="require('@/static/img_events/Eventos_estatus.svg')" alt="Estatus">
                      </a-col>
                      <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                        <div class="title-bold-book event-status">Estatus</div>
                        <div class="d-flex align-items-center" v-if="event.status == 'Aprobado'">
                          <div class="status-active"></div>
                          <span class="title-normal status-name">Activo</span>
                        </div>
                        <div class="d-flex align-items-center" v-if="event.status == 'Registro finalizado'">
                          <div class="status-close-register"></div>
                          <span class="title-normal status-name">Registro finalizado</span>
                        </div>
                        <div class="d-flex align-items-center" v-if="event.status == 'Finalizado'">
                          <div class="status-close"></div>
                          <span class="title-normal status-name">Finalizado</span>
                        </div>
                        <div class="d-flex align-items-center" v-if="event.status == 'Cancelado'">
                          <div class="status-cancel"></div>
                          <span class="title-normal status-name">Cancelado</span>
                        </div>
                        <div class="d-flex align-items-center" v-if="event.status == 'No aprobado'">
                          <div class="status-cancel"></div>
                          <span class="title-normal status-name">No validado</span>
                        </div>
                        <div class="d-flex align-items-center" v-if="event.status == 'Por aprobar'">
                          <div class="status-close-register"></div>
                          <span class="title-normal status-name">Por validar</span>
                        </div>
                      </a-col>
                    </a-row>
                    <a-row class="list-data">
                      <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                        <img class="logo" :src="require('@/static/img_events/SEJ enventos_adjuntos.svg')" alt="adjuntos" />
                      </a-col>
                      <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                        <span class="title-normal title_color-secondary open-modal" v-on:click.stop="openModal(event.id)">
                          Ver los archivos de la convocatoria
                        </span>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row class="card_footer list-data" style="padding-bottom: .8rem;padding-top: .8rem; height: auto;">
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="section !== 'interest'">
                    <a-row :gutter="8" class="list-data" v-if="roles[0].nombre == CCT_DIRECTOR || roles[0].nombre == PARTICIPANT || roles[0].nombre.toLowerCase() == 'responsable de registro'">
                      <a-col :xxl="11" :lg="11" :md="11" :sm="24" :xs="24" v-if="roles[0].nombre === CCT_DIRECTOR && event.registered_assistances && !(event.status == 'Por aprobar' || event.status == 'No aprobado')">
                        <button class="buttons btn-view-participants" @click.stop="handlerSendViewParticipants(event.id, event.grouped_events)"
                        >
                          <img class="options-logo" :src="require('@/static/img_events/Eventos_ver_participantes_azul.svg')" alt="Ver participantes" />
                          {{ event.grouped_events ? "Ver eventos" : "Ver participantes" }}
                        </button>
                      </a-col>
                      <a-col :xxl="11" :lg="11" :md="11" :sm="24" :xs="24"
                             v-if="event.allow_edit && event.activities && !event.grouped_events && !(event.status == 'Finalizado' || event.status == 'Cancelado')">

                          <button class="buttons btn-view-participants" @click.stop="handlerSendViewEditForm(event.assistant_event_id)">
                            <img class="options-logo" :src="require('@/static/img_events/Eventos_editar_formulario_azul.svg')" alt="Ver participantes" />
                            Editar formulario
                          </button>

                      </a-col>
                      <a-col :xxl="11" :lg="11" :md="11" :sm="24" :xs="24" v-if="event.already_registered && !(event.status == 'Finalizado' || event.status == 'Cancelado')">
                        <button class="buttons btn-unregister" @click="openModalUnregister('unregister', event.id)"
                        >
                          <img class="options-logo" :src="require('@/static/img_events/Eventos_darme_de_baja_rosa.svg')" alt="Darme de baja">
                          Darme de baja
                        </button>
                      </a-col>
                      <a-col :xxl="11" :lg="11" :md="11" :sm="24" :xs="24" v-if="dateIsAvailableToRegisterCode(event) && !event.link_showed && eventHasVirtualHeadquarter(event) && event.status == 'Aprobado'">
                        <button class="buttons btn-view-participants" @click="handleModalRegisterCode(event)"
                        >
                          <img class="options-logo" :src="require('@/static/img_events/SEJ_Eventos_casillas de verificacion.svg')" alt="Registrar código">
                          Registrar código
                        </button>
                      </a-col>
                    </a-row>
                    <a-row :gutter="25" v-else>
                      <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" class="text-right" style="height: 38px;">
                        <sdDropdown :action="['click']">
                          <template #overlay>
                            <div class="dropdown-content">
                              <button class="btn-dropdown" @click.stop="handlerSendViewParticipants(event.id, event.grouped_events)"
                                v-if="event.type === 'normal' && !(event.status == 'Por aprobar' || event.status == 'No aprobado') && ((roles[0].nombre.toLowerCase() == 'responsable de evento' && event.responsible_id == loggedUser.id)
                                      || (removeAccents(roles[0].nombre).toLowerCase() == 'administrador de area')
                                      || (roles[0].nombre.toLowerCase() == 'administrador general'))"
                              >
                                <img :src="require('@/static/img_events/Eventos_ver_participantes.svg')" alt="Ver participantes">
                                {{ event.grouped_events ? "Ver eventos" : "Ver participantes" }}
                              </button>
                              <button @click="handleDownloadFolio(event.id)" class="btn-dropdown" v-if="event.already_registered">
                                  <img :src="require('@/static/img_events/Eventos_descargar_folio.svg')" alt="Descargar Folio">
                                Descargar folio
                              </button>
                              <button class="btn-dropdown" @click.stop="handlerSendViewEditForm(event.assistant_event_id)"
                                      v-if="event.allow_edit && !event.grouped_events &&
                                      !(event.status == 'Finalizado' || event.status == 'Cancelado')">
                                <img class="options-logo" :src="require('@/static/img_events/Eventos_editar_formulario.svg')" alt="Ver participantes" />
                                Editar formulario
                              </button>
                              <button class="btn-dropdown" @click="openModalUnregister('unregister', event.id)"
                                v-if="event.already_registered"
                              >
                                <img :src="require('@/static/img_events/Eventos_darme_de_baja.svg')" alt="Darme de baja">
                                Darme de baja
                              </button>
                              <button class="btn-dropdown" @click.stop="goToEditEventForm(event.id, event.status)"
                                v-if="(event.status == 'No aprobado' || event.status == 'Aprobado') && event.creator"
                              >
                                <img :src="require('@/static/img_events/Eventos_editar_evento.svg')" alt="Editar evento">
                                Editar evento
                              </button>
                              <button class="btn-dropdown" @click="openModalUnregister('cancelEvent', event.id)"
                                v-if="event.status !== 'Finalizado' && (
                                        (roles[0].nombre.toLowerCase() === 'responsable de evento' && (event.creator || event.responsible_id == loggedUser.id)) ||
                                        (roles[0].nombre.toLowerCase() === 'administrador general') ||
                                        (removeAccents(roles[0].nombre).toLowerCase() === 'administrador de area')
                                      )"
                              >
                                <img :src="require('@/static/img_events/Eventos_cancelar_evento.svg')" alt="Cancelar evento">
                                Cancelar evento
                              </button>
                              <button class="btn-dropdown" v-if="dateIsAvailableToRegisterCode(event) && !event.link_showed && eventHasVirtualHeadquarter(event)"  @click="handleModalRegisterCode(event)"
                              >
                                <img class="options-logo" :src="require('@/static/img_events/SEJ_Eventos_casillas de verificacion.svg')" alt="Registrar código">
                                Registrar código
                              </button>
                            </div>
                          </template>
                          <button class="btn-options" v-if="event.status !== 'Cancelado'">
                            <img :src="require('@/static/img_events/SEJ_Eventos_opciones-21.svg')" alt="opciones" />
                          </button>
                        </sdDropdown>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="section === 'interest'">
                    <a-row :gutter="10" class="options-interest">
                      <a-col :xxl="10" :lg="10" :md="10" :sm="10" :xs="10" class='registers'>
                        <div class="d-flex align-items-center" style="height: 36px;">
                          <img
                            :src="require(`@/static/img_events/SEJ_Eventos_asistentes.svg`)"
                            alt="logo" width="16" height="16"
                          />
                          {{`${event.participants_count == undefined ? 0 : event.participants_count} registros`}}
                        </div>
                      </a-col>
                      <a-col :xxl="10" :lg="10" :md="10" :sm="10" :xs="9">
                        <router-link :to="`/home/register/participant/${event.id}`"
                          v-if="dateIsAvailableToRegister(event) && event.type === 'normal' && event.already_registered && !(event.status == 'Finalizado' || event.status == 'Cancelado')"
                        >
                          <button class="buttons btn-register">
                            Registrarme
                          </button>
                        </router-link>
                      </a-col>
                      <a-col :xxl="4" :lg="4" :md="4" :sm="4" :xs="5">
                        <button class="buttons btn-favorite" @click="toggleFavorite(event.id)" v-if="!(event.status == 'Cancelado' || event.status == 'Finalizado')">
                          <img :src="require('@/static/img_events/SEJ_Eventos_me interesa.svg')"
                              alt='me interesa'
                          >
                        </button>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </a-tooltip>
          </RegisterStyleCard>
        </template>
        <template #fallback>
          <sdCards headless>
            <a-skeleton active />
          </sdCards>
        </template>
      </Suspense>
    </a-col>
    <a-col :xxl="24" :lg="24" :md="124" :xs="24" style="text-align:center; margin-bottom: 2rem;" v-if="events.length && page < totalPages">
      <span class="title-more-events" v-on:click="showMoreElements">Ver más</span>
    </a-col>
  </a-row>
  <a-row :gutter="25" v-else>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;" v-if="loading">
      <a-spin></a-spin>
    </a-col>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;" v-else>
      <span 
        class="no-events-label"
        v-if="section === 'register'">
        <span v-if="roles[0].nombre === (CCT_DIRECTOR || PARTICIPANT)">
          No te has registrado a ningún evento
        </span>
        <span v-else>
          No te has registrado/creado a ningún evento
        </span>
      </span>
      <span class="no-events-label" v-else>
        No has guardado ningún evento
      </span>
    </a-col>
  </a-row>

  <sdModal
    type="primary"
    :width="300"
    :onOk="handleOk"
    :visible="dataDOM.visibleUnregister"
    :onCancel="handleCancel">
    <div>
      <p class="title-modal mt-3"
        :class="dataDOM.typeModal === 'cancelEvent' ? 'text-center' : ''">{{ dataDOM.titleModal }}</p>
    </div>
    <div v-if="dataDOM.unregisterLoading" class="modal-buttons">
      <a-spin size="small" />
    </div>
    <div v-else class="modal-buttons">
      <div v-if="dataDOM.typeModal === 'unregister'">
        <button class="btn-cancel" @click="handleCancel">Cancelar</button>
        <button class="btn-accept" @click="unregisterEvent">Aceptar</button>
      </div>
      <div v-if="dataDOM.typeModal === 'cancelEvent'">
        <button class="btn-cancel" @click="handleCancel">Cancelar</button>
        <button class="btn-accept" @click="handleCancelEvent">Aceptar</button>
      </div>
    </div>
  </sdModal>

  <sdModal
    :title="dataDOM.titleModal"
    type="primary"
    :onOk="handleOk"
    :visible="dataDOM.visible"
    :onCancel="handleCancel"
    class="title-bold-normal">
    <div v-if="dataDOM.filesNames.length">
      <p
        class="title-normal type-btn"
        v-for="item in dataDOM.filesNames"
        :key="item"
        v-on:click="handleDownloadFile(item.route, item.name)"
        style="color: #e70e4c"
        >
        <sdFeatherIcons type="download" /> {{ item.name }}
        <br>
      </p>
    </div>
    <div v-else>
      <p class="title-normal">
        No hay información para mostrar
      </p>
    </div>
  </sdModal>

<!--INICIO::MODAL REGISTRAR CODIGO-->
  <sdModal
    type="primary"
    :width="300"
    :visible="visibleModalCode"
    :onCancel="handleCancelModalCode">
    <div class="mt-4">
      <p class="title-normal">
        Ingrese el código de acceso (viene en el folio generado al momento de registrarse al evento)
      </p>
      <FormValidationWrap>
        <VerticalFormStyleWrap>
          <a-form
            name="sDash_validation-form"
            :ref="formRef"
            :model="modelRegisterCode"
            :rules="rulesRegisterCode"
            layout="vertical"
          >
            <a-row :gutter="30">
              <a-col :md="24" :xs="24">
                <a-form-item ref="code" label="Código" name="code" class="form-font">
                  <a-input type="text" v-model:value="modelRegisterCode.code" placeholder="" class="input-field" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="25" style="text-align: center;">
              <a-col :xxl="12" :lg="12" :md="12" :xs="12" class="text-right">
                <button class="btn-outlined-exit" @click="handleCancelModalCode">
                  <span class="title-normal"> Cancelar </span>
                </button>
              </a-col>
              <a-col :xxl="12" :lg="12" :md="12" :xs="12" class="text-left">
                <a-button :loading="dataDOM.loadingRegisterCode" class="btn-fill-add" v-on:click="handleValidateRegisterCode">
                  <span class="title-normal"> {{  'Acceder' }} </span>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </VerticalFormStyleWrap>
      </FormValidationWrap>
    </div>
  </sdModal>
  <!--FIN::MODAL REGISTRAR CODIGO-->
</template>

<script>
import { RegisterStyleCard, RegisterStyleCardYellow } from '../../style';
import { reactive, ref } from 'vue';
import { useRouter } from "vue-router";
import { getMyEvents, getMyFavoriteEvents, unregisterToEvent, getFileDetail, cancelEvent, validateRegisterCode } from '@/services/events';
import { addToFavorites, getFileFolioRegisterToEvent, getParticipantsCountByEvents } from '@/services/home'
import { getItem } from '../../../../utility/localStorageControl';
import { formatDate, removeAccents, removeSeconds } from '@/utility/utility'
import { Notification } from 'ant-design-vue';
import { searchFavoriteEvents } from '../../../../services/events';
import {GENERAL_ADMIN, EVENT_MANAGER, AREA_ADMIN, PARTICIPANT, CCT_DIRECTOR} from '@/constants/roles';

const ResgisterComponent = {
  name: 'ResgisterComponent',
  components: { RegisterStyleCard, RegisterStyleCardYellow },
  props: [
    "section",
    "search",
  ],
  data () {
    return {
      loading: false,
      events: [],
      page: 1,
      totalPages: 0,
      groupsEventsGlobal: [],
      eventsGroups: [],
      indexGroup: 0,
      base_url: process.env.VUE_APP_BASE_URL,
      visibleModalCode: false,
      loadingRegisterCode: false,
      actualEventRegisterCode: null,
      rulesRegisterCode: {
        code: [
          {
            required: false,
            message: 'Este campo es requerido',
            trigger: 'blur',
            type: 'string',
          },
        ],
      },
      modelRegisterCode: {
        code: ""
      }
    }
  },
  mounted() {
    this.getRegisteredEvents()
  },
  methods: {
    async getRegisteredEvents () {
      this.loading = true;
      let events = [];
      await getMyEvents(this.page, this.search)
        .then((response) => {
          if (response.success) {
            response.events.data.forEach(event => {
              if (typeof(event.files) === 'string') {
                event.files = JSON.parse(event.files);
              }
            })
            events = this.events.concat(response.events.data);
            this.totalPages = response.events.last_page
          } else {
            events = [];
          }
        })
      this.events = events;
      this.loading = false;
    },

    async getFavoriteEvents () {
      this.loading = true;
      let events = [];
      await getMyFavoriteEvents(this.page)
        .then((response) => {
          if (response.success) {
            response.data.data.forEach(event => {
              if (typeof(event.files) === 'string') {
                event.files = JSON.parse(event.files);
              }
            })
            events = this.events.concat(response.data.data);
            this.totalPages = response.data.last_page
          } else {
            events = [];
          }
        })
      this.events = events;
      this.loading = false;
      this.getParticipantsCount()
    },

    async getSearchFavoriteEvents () {
      this.loading = true;
      let events = [];
      await searchFavoriteEvents(this.search)
        .then((response) => {
          if (response.success) {
            response.data.data.forEach(event => {
              if (typeof(event.files) === 'string') {
                event.files = JSON.parse(event.files);
              }
            })
            events = this.events.concat(response.data.data);
            this.totalPages = response.data.last_page
          } else {
            events = [];
          }
        })
      this.events = events;
      this.loading = false;
    },

    async getParticipantsCount () {
      let ids = this.events.map(event => event.id);
        getParticipantsCountByEvents(ids)
          .then((response) => {
            if (response.success) {
              this.events.forEach(event => {
                event.participants_count = response.data[event.id];
              });
            }
          })
          .catch(() => {});
    },

    showMoreElements () {
      // this.indexGroup++;
      // this.eventsGroups[this.indexGroup].forEach((element) => {
      //   this.events.push(element)
      // })
      if (this.section === "register") {
        this.page++;
        this.getRegisteredEvents();
      }
      if (this.section === "interest") {
        this.page++;
        this.getFavoriteEvents();
      }
    },
    unregisterEvent() {
      this.dataDOM.unregisterLoading = true;
      unregisterToEvent(this.dataDOM.idEventUnregister)
      .then((response) => {
        if (response.success) {
          location.reload();
        } else {
          Notification.error({
            message: "Error",
            description: response.message || "Ocurrió un error al darse de baja",
          });
        }
        this.dataDOM.visibleUnregister = false;
        this.dataDOM.idEventUnregister = null;
        this.dataDOM.unregisterLoading = false;
      })
      .catch(() => {
        Notification.error({
          message: "Error",
          description: "Ocurrió un error al darse de baja",
        });
        this.dataDOM.visibleUnregister = false;
        this.dataDOM.idEventUnregister = null;
        this.dataDOM.unregisterLoading = false;
      }); 
    },

    async handleCancelEvent () {
      this.dataDOM.unregisterLoading = true;
      await cancelEvent (this.dataDOM.idEventUnregister)
        .then((response) => {
          if (response.success) {
            location.reload();
          } else {
            Notification.error({
              message: "Error",
              description: response.message || "Ocurrió un error al cancelar evento",
            });
          }
          this.dataDOM.visibleUnregister = false;
          this.dataDOM.idEventUnregister = null;
          this.dataDOM.unregisterLoading = false;
        })
        .catch(() => {
          Notification.error({
            message: "Error",
            description: "Ocurrió un error al darse de baja",
          });
          this.dataDOM.visibleUnregister = false;
          this.dataDOM.idEventUnregister = null;
          this.dataDOM.unregisterLoading = false;
        }); 
    },

    openModal (idEvent) {
      //console.log(idEvent);
      const event = this.events.filter(event => event.id == idEvent)[0];
      //console.log(event);
      this.dataDOM.titleModal = "Archivos de la convocatoria";
      this.dataDOM.filesNames = event?.files || [];
      this.dataDOM.visible = true;
    },
    
    async toggleFavorite (idEvent) {
      await addToFavorites(idEvent)
        .then((response)=>{
          if(response.success){
            this.events = [];
            this.getFavoriteEvents()
          }
        })
    },
    async handleDownloadFolio (eventId) {
      await getFileFolioRegisterToEvent(eventId).then((response) => {
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', "folio_" + eventId + ".pdf");
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }).catch(() => {

      })
    },
    dateIsAvailableToRegister(event){
      let currentDate = new Date();
      let startRegisterDate = new Date(event.start_register_date);
      let endRegisterDate = new Date(event.end_register_date);

      //currentDate.setTime(currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000) //Acomodar el offset del horario
      currentDate.setHours(0,0,0,0);
      startRegisterDate.setTime(startRegisterDate.getTime() + startRegisterDate.getTimezoneOffset() * 60 * 1000) //Acomodar el offset del horario
      endRegisterDate.setTime(endRegisterDate.getTime() + endRegisterDate.getTimezoneOffset() * 60 * 1000) //Acomodar el offset del horario

      return currentDate >= startRegisterDate && currentDate <= endRegisterDate;
    },
    dateIsAvailableToRegisterCode(event) {
      let currentDateWithoutHours = new Date();
      currentDateWithoutHours.setHours(0,0,0)
      currentDateWithoutHours.setMilliseconds(0)
      let startDayEvent = new Date(`${event.event_date} ${'00:00'}`);

      let currentDate = new Date();
      let startRegisterDate = new Date(`${event.event_date} ${event.event_hour}`);
      let endDayEvent = new Date(`${event.end_date} ${event.end_time}`);
      let diffHoursStart = Math.abs(currentDate - startRegisterDate);
      diffHoursStart = Math.floor((diffHoursStart / 1000) / 60)

      if(!event.link_showed){
        if((startDayEvent.getTime() == currentDateWithoutHours.getTime()) && diffHoursStart <= 30){
          return true;
        }
        if((currentDate.getTime() <= endDayEvent.getTime()) && (startRegisterDate.getTime() <= currentDate.getTime())){
          return true
        }
        return false;
      } else {
        return false;
      }
    },
    eventHasVirtualHeadquarter(event){
      let hasOne = event.headquarters.find((headquarter) => {
        return headquarter.type == "both" ||  headquarter.type == "virtual"
      })
      return hasOne != undefined;
    },
    handleModalRegisterCode(event) {
      this.visibleModalCode = true;
      this.actualEventRegisterCode = event.id;
    },
    handleValidateRegisterCode(){
      this.sendTovalidateCode();
    },
    async sendTovalidateCode() {
      const data = {
        code: this.modelRegisterCode.code
      }
      await validateRegisterCode(this.actualEventRegisterCode, data).then((response) => {
        if(response.success){
          Notification['success']({
            message: "Éxito",
            description: "Acción realizada con éxito",
          });
          this.events.find((event) => {
            if(event.id == this.actualEventRegisterCode) event.link_showed = 1;
          })
          this.visibleModalCode = false;
        } else {
          Notification['error']({
            message: "Error",
            description: "Código invalido",
          });
        }
      }).catch(() => {
      })
    },
    handleCancelModalCode() {
      this.visibleModalCode = false;
    }
  },
  watch: {
    section: function (value) {
      if (value === "register") {
        this.events = [];
        this.page = 1;
        this.getRegisteredEvents();
      }
      if (value === "interest") {
        this.events = []
        this.page = 1;
        this.getFavoriteEvents();
      }
    },
    search: function () {
      if (this.section === "register") {
        this.events = [];
        this.page = 1;
        this.getRegisteredEvents();
      }
      if (this.section === "interest") {
        if (this.search === '') {
          this.events = []
          this.page = 1;
          this.getFavoriteEvents();
        } else {
          this.events = []
          this.page = 1;
          this.getSearchFavoriteEvents();
        }
      }
    }
  },
  async setup() {
    const router = useRouter();
    const formRef =  ref();
    var groupsEventsGlobal = reactive([])
    const roles = getItem("roles");
    roles[0].nombre = roles[0].nombre.normalize('NFD')
     .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
     .normalize();
    const loggedUser = getItem('userData');

    const userCanDoThis = () => {
      for(let i = 0; i < roles.length; i++){
        if(roles[i].permiso == "activo" && roles[i].nombre == PARTICIPANT){
          return false;
        }
      }
      return true;
    }
    
    var dataDOM = reactive({
      userCanDoThis: userCanDoThis(),
      filesNames: [],
      titleModal: '',
      visible: false,
      visibleUnregister: false,
      idEventUnregister: null,
      typeModal: '',
      unregisterLoading: false,
      bodyTitle: '',
      eventsGroups: groupsEventsGlobal,
      indexGroup: 0,
    });

    const handleOk = () => {
      dataDOM.visible = false;
      dataDOM.visibleUnregister = false;
    }

    const openModalUnregister = async (type, idEvent) => {
      dataDOM.titleModal = type === 'unregister'
        ? '¿Estás seguro que te quieres dar de baja de este evento?'
        : '¿Estás seguro de cancelar este evento?'
      dataDOM.typeModal = type;
      dataDOM.idEventUnregister = idEvent;
      dataDOM.visibleUnregister = true;
    }

    const handleCancel = () => {
      dataDOM.visible = false;
      dataDOM.visibleUnregister = false;
    }

    const handleDownloadFile = async (route, nameFile) => {
      getFileDetail(route).then((response) => {
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nameFile);
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }).catch(() => {

      })
    }

    const downloadFolio = (folio) => {
      console.log(folio)
    }

    const handlerSendEventDetail = (eventId) => {
      router.push({name: 'myEventsDetail', params: { id: eventId}});
    }

    const handlerSendViewParticipants = (eventId, hasChild) => {
      if(hasChild){
        router.push({name: 'subevents', params: { id: eventId }});
      } else {
        router.push({name: 'participants', params: { id: eventId }});
      }
    }
    const goToEditEventForm = (eventId, status) =>{
      if(status!= 'No Validado'){
        router.push({name: 'editValidEvent', params: { id: eventId }});
      }else{
        router.push({name: 'editNotValidEvent', params: { id: eventId }});
      }

    }
    const handlerSendViewEditForm = (eventId) => {
      // `/events/edit/${event.id}`
      router.push({name: 'editAnswers', params: { id: eventId }});
    }

    const showTooltip = () => {
      alert('tooltip')
    }

    return {
      formRef,
      dataDOM,
      handleOk,
      handleCancel,
      openModalUnregister,
      handleDownloadFile,
      formatDate,
      downloadFolio,
      handlerSendEventDetail,
      handlerSendViewParticipants,
      handlerSendViewEditForm,
      loggedUser,
      showTooltip,
      roles,
      GENERAL_ADMIN, EVENT_MANAGER, AREA_ADMIN, PARTICIPANT, CCT_DIRECTOR,
      goToEditEventForm,
      removeAccents,
      removeSeconds
    };
  },
};

export default ResgisterComponent;
</script>

<style lang="scss" scoped>
.bt-option-card{
  width: 100%;
  color: #212529;
  background: none;
  border: none;
  padding-left: 15px !important;
  padding: 5px;
  font-size: .8vw;
  margin-bottom: 0.5rem;
}
.bt-option-card:hover {
  cursor: pointer;
  background: #f1efef;
}
.list-data{
  margin-bottom: 0.5rem;
}
.logo {
  width: 12px;
  height: 14px;
}
.options-logo {
  width: 20px;
}
.min-height {
  height: 52px;
}
.open-modal {
  font-family: Avenir Heavy;
  font-size: 12px;
  cursor: pointer;
}
.box-event {
  border-radius: 20px;
  padding: 0 !important;
  /* margin-left: 0.5rem; */
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 6px;
  border: none;
}
.btn-add-event:hover {
  cursor: pointer;
}
.title-more-events {
  color: #3FA7E1;
  font-size: 15px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
.type-btn:hover{
  cursor: pointer;
}
.status-active{
  background: #51AF2C;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin-right: 4px;
}
.status-close-register {
  background: #FEC62E;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin-right: 4px;
}
.status-close {
  background: #3FA7E1;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin-right: 4px;
}
.status-cancel{
    background: #E8394D;
    border-radius: 8px;
    width: 8px;
    height: 8px;
    margin-right: 4px;
}
.buttons {
  width: 100%;
  margin-top: .5rem;
}
.interest {
  border-top: 1px solid #CCCCCC;
  width: 100%;
  margin-top: -15px;
  padding: 1rem 2vw;
  .participants-logo{
    width: .8vw;
    height: 3vh;
  }
  .people-register {
    font-size: .8vw;
    margin-left: .5rem;
  }
}
.btn-options {
  background-color: transparent;
  border: none;
  img {
    width: 34px;
    height: 34px;
    @media only screen and (max-width: 1400px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.btn-view-participants {
  background-color: #FFFFFF;
  border: 2px solid #3FA7E1;
  border-radius: 24px;
  color: #3FA7E1;
  font-family: Avenir Heavy;
  font-size: 10px;
  height: 34px;
  padding: .4rem 0;
  text-align: center;
  cursor: pointer;
}
.btn-unregister {
  background-color: transparent;
  color: #E8394D;
  border-radius: 24px;
  border: 2px solid #E8394D;
  font-family: Avenir Heavy;
  font-size: 10px;
  height: 34px;
  padding: .4rem 0;
  text-align: center;
  cursor: pointer;
}
.title-modal {
  color: #7B868C;
  font-size: 14px;
}
.modal-buttons {
  width: 100%;
  margin: 20px auto 0;
  display: flex;
  justify-content: flex-end;
  .btn-cancel {
    background-color: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 20px;
    color: #404040;
    font-size: 12px;
    padding: .3rem;
    width: 90px;
    margin: 0 5px;
  }
  .btn-cancel:hover{
    cursor: pointer;
  }
  .btn-accept {
    background-color: #3FA7E1;
    border: none;
    border-radius: 20px;
    color: #FFFFFF;
    font-size: 12px;
    padding: .3rem;
    width: 90px;
    margin: 0 5px;
  }
  .btn-accept:hover {
    cursor: pointer;
  }
}
.options-interest {
  display: flex;
  justify-content: flex-end;
  margin-bottom: .3rem;
  .registers {
    display: flex;
    align-items: center;
    color: #7B868C;
    font-size: 11px;
    font-family: 'Avenir Heavy';
  }
  .btn-register {
    background-color: #E8394D;
    border: none;
    border-radius: 24px;
    color: #FFFFFF;
    font-family: Avenir Heavy;
    font-size: 10px;
    height: 34px;
    &:hover {
      cursor: pointer;
    }
  }
  .btn-favorite {
    background-color: #F9CDD2;
    border: 1px solid #F9CDD2;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.dropdown-content {
  width: 230px;
  text-align: center;
  .btn-dropdown {
    width: 90%;
    background-color: transparent;
    border: none;
    text-align: left;
    padding: .3rem 1rem;
    font-size: 14px;
    font-weight: 600;
    @media only screen and (max-width: 1400px) {
      height: 33px;
    }
    img{
      width: 20px;
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
      color: #3FA7E1;
    }
  }
}
.status-name{
  font-size: 12px;
}
</style>
<style lang="scss">
.event-card {
  .ant-tooltip-content {
    outline: 3px solid #51AF2C;
    .ant-tooltip-arrow-content {
      border: 3px solid #51AF2C;
    }
  }
  .ant-tooltip-inner {
    font-family: Avenir Heavy;
    color: #404040;
  }
  .break-text-one-lines{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
  }
}
</style>
<style scoped lang='sass'>
.events-activities
  background-color: #FFFFFF
  border-top: 1px solid #CCCCCC
.btn-fill-add
  border: 1px solid #3FA7E1
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px
  cursor: pointer
.btn-disabled:hover
  cursor: not-allowed !important
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem
  cursor: pointer
</style>